import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 2462.000000 2614.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,2614.000000) scale(0.100000,-0.100000)">
<path d="M13000 21449 c-1303 -81 -2519 -551 -3537 -1366 -1794 -1436 -2653
-3743 -2238 -6013 45 -247 123 -552 201 -790 66 -202 176 -496 191 -510 6 -6
256 90 271 105 1 1 -18 53 -43 115 -191 476 -331 1030 -389 1540 -55 484 -46
1047 24 1526 127 867 446 1706 925 2429 264 399 574 766 903 1071 1035 958
2292 1500 3697 1595 214 15 747 6 945 -15 1240 -133 2310 -581 3255 -1365 183
-152 572 -539 719 -716 597 -717 1003 -1502 1226 -2370 173 -672 226 -1401
154 -2095 -210 -2028 -1438 -3802 -3264 -4716 -1255 -627 -2683 -791 -4057
-464 -875 208 -1746 647 -2422 1219 -62 53 -121 102 -131 109 -16 11 -28 1
-111 -93 -52 -59 -94 -110 -94 -114 0 -10 125 -119 255 -222 884 -702 1942
-1154 3050 -1303 330 -44 445 -51 855 -51 393 0 476 5 797 45 1785 226 3415
1241 4413 2750 564 852 898 1796 1010 2855 22 205 31 780 16 1000 -95 1376
-593 2601 -1488 3665 -139 165 -563 585 -733 727 -1050 874 -2303 1370 -3667
1453 -193 11 -544 11 -733 -1z"/>
<path d="M10365 18811 c-174 -15 -284 -49 -620 -193 -319 -136 -481 -229 -663
-383 -273 -229 -484 -581 -561 -939 -60 -275 -37 -642 56 -901 160 -444 531
-797 1103 -1049 360 -158 692 -261 1295 -401 338 -78 613 -170 775 -258 52
-28 102 -67 160 -126 130 -131 180 -243 180 -406 0 -156 -49 -272 -169 -398
-156 -163 -345 -239 -634 -254 -465 -23 -840 169 -1026 527 -58 111 -111 274
-136 415 -23 132 -20 125 -57 125 -19 0 -425 -25 -903 -55 -478 -30 -879 -55
-892 -55 -20 0 -23 -5 -23 -37 0 -63 38 -289 71 -422 104 -425 317 -808 619
-1111 314 -316 784 -506 1460 -589 471 -58 1116 -56 1528 5 629 92 1124 323
1486 692 l68 70 -27 108 c-86 345 -310 722 -698 1175 -217 254 -357 403 -1007
1069 -538 551 -755 784 -1042 1119 l-157 184 -138 84 c-75 45 -135 83 -132 83
4 0 39 -14 80 -31 41 -17 85 -35 99 -40 l24 -10 -19 23 c-11 13 -43 49 -72 80
-63 70 -93 145 -93 231 0 34 3 72 6 85 l6 22 1872 -2 1871 -3 3 -762 2 -761
98 -70 c261 -186 528 -417 818 -707 323 -323 555 -592 866 -1006 l163 -216 5
1761 5 1761 1003 3 1002 2 0 780 0 780 -3657 0 c-2012 0 -3714 2 -3783 4 -69
3 -165 1 -215 -3z"/>
<path d="M8803 7950 c-41 -25 -63 -59 -70 -108 -8 -60 19 -104 112 -182 111
-93 135 -123 135 -169 0 -49 -30 -87 -79 -102 -32 -9 -44 -8 -75 6 -20 10 -46
32 -58 49 -12 17 -23 32 -24 34 -1 2 -14 -3 -29 -10 l-27 -14 34 -44 c42 -55
89 -80 151 -80 114 0 195 98 167 201 -13 49 -63 105 -150 171 -102 78 -125
133 -75 183 37 38 85 34 134 -11 35 -31 42 -34 60 -22 28 17 26 29 -11 65 -58
56 -136 69 -195 33z"/>
<path d="M10124 7945 c-11 -29 2 -469 15 -500 17 -41 48 -73 95 -97 59 -30
160 -23 210 15 69 53 71 63 74 345 l3 252 -25 0 -24 0 -4 -237 c-3 -228 -4
-240 -26 -275 -31 -50 -96 -76 -151 -59 -55 16 -89 46 -101 89 -5 20 -10 137
-10 260 l0 222 -25 0 c-14 0 -28 -7 -31 -15z"/>
<path d="M11628 7954 c-5 -4 -8 -144 -8 -312 l0 -304 118 4 c137 4 180 20 220
81 20 30 26 52 26 91 0 64 -23 108 -71 137 -21 12 -40 24 -42 26 -3 2 5 10 16
18 11 8 28 33 37 56 30 74 3 150 -66 185 -34 17 -218 32 -230 18z m191 -65
c31 -11 61 -52 61 -84 0 -32 -32 -77 -65 -91 -19 -8 -57 -14 -85 -14 l-50 0 0
100 0 100 54 0 c29 0 67 -5 85 -11z m5 -259 c84 -19 127 -103 86 -168 -27 -44
-74 -62 -160 -62 l-70 0 0 120 0 120 53 0 c28 0 70 -5 91 -10z"/>
<path d="M12997 7954 c-4 -4 -7 -18 -7 -31 0 -22 3 -23 70 -23 l70 0 0 -280 0
-280 30 0 30 0 0 280 0 280 71 0 70 0 -3 28 -3 27 -161 3 c-88 1 -163 -1 -167
-4z"/>
<path d="M14350 7650 l0 -310 30 0 30 0 0 310 0 310 -30 0 -30 0 0 -310z"/>
<path d="M15511 7657 c-23 -166 -41 -306 -41 -310 0 -5 12 -7 27 -5 l27 3 28
205 c15 112 29 211 30 220 2 8 53 -85 112 -207 60 -123 112 -223 116 -223 3 0
55 100 115 221 l110 222 30 -219 30 -219 29 -3 c28 -3 29 -2 24 30 -3 18 -23
156 -43 306 -21 149 -41 272 -44 272 -4 0 -60 -110 -125 -245 -66 -135 -122
-245 -126 -245 -3 0 -60 111 -125 246 -66 135 -122 247 -125 249 -4 2 -25
-132 -49 -298z"/>
<path d="M17220 7650 l0 -310 175 0 175 0 0 30 0 30 -145 0 -145 0 0 120 0
119 143 3 142 3 3 28 3 27 -145 0 -146 0 0 100 0 100 145 0 145 0 0 30 0 30
-175 0 -175 0 0 -310z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
